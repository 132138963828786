
import { Component, Vue, Watch } from "vue-property-decorator";
import { JFFormControl, JFTextField } from "jfrog-ui-vue-essentials";
import { MarketplaceRegistrationRequest } from "@jfrog-ba/myjfrog-common";

@Component({
  name: "CloudCredentials",
  $_veeValidate: { validator: "new" },
  components: { JFFormControl, JFTextField },
})
export default class CloudCredentials extends Vue {
  data: MarketplaceRegistrationRequest = {
    firstName: "",
    lastName: "",
    companyName: "",
    phone: "",
    email: "",
    regionCode: "",
    regionName: "",
    password: "",
    serverName: "",
    subscriptionType: "",
    mpToken: "",
    awsToken: "",
    instanceUrl: "",
    cloudProvider: "",
  };

  mounted() {
    this.loadFormDataFromSession();
  }

  @Watch("data", { deep: true })
  onDataChange() {
    setTimeout(() => {
      this.handleOnDataChange();
    }, 300);
  }

  handleOnDataChange() {
    if (this.errors.any()) {
      this.$emit("credentialsFilled", false);
      return;
    }
    let token = this.$route.query.token as string;
    if (token) {
      token = decodeURIComponent(token).replaceAll(" ", "+");
      this.data.mpToken = token;
      this.data.awsToken = token;
      this.data.subscriptionType = this.$route.query.pt as string;
    }
    sessionStorage.setItem("registrationFormData", JSON.stringify(this.data));
    if (this.data.firstName && this.data.lastName && this.data.companyName && this.isValidPhone && this.data.email) {
      this.$emit("credentialsFilled", true);
    }
  }

  get isValidPhone() {
    if (!this.showPhone) {
      return true;
    }
    return this.data.phone?.length > 9;
  }

  get showPhone() {
    return this.data.subscriptionType === "TRIAL_AWS_ENTERPRISE_X";
  }

  get emailCss() {
    if (this.showPhone) {
      return "w-100";
    }
    return "w-45";
  }

  async loadFormDataFromSession() {
    const data = sessionStorage.getItem("registrationFormData");
    if (data) {
      this.data = JSON.parse(data);
      this.validateUI();
    }
  }

  validateUI() {
    const CloudCredentialsVisited = sessionStorage.getItem("mpCredentialsVisited");
    if (CloudCredentialsVisited && CloudCredentialsVisited === "true") {
      setTimeout(async () => {
        await this.$validator.validateAll();
      }, 300);
    }
    sessionStorage.setItem("CloudCredentialsVisited", "true");
  }
}
